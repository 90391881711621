$( document ).ready(function() {
    $("#scrolltop").click(function () {
        return $("html, body").animate({scrollTop: 0}, "slow"), !1
    });
    $(".nano").nanoScroller();
});
jQuery(document).ready(function($) {
    $(".clickable-row").on('click tap', function () {
        window.document.location = $(this).data("href");
    });
});
$('#myModal').on('shown.bs.modal', function () {
    $('#myInput').focus()
});
function test(){

}